<template>
    <div></div>
</template>
<script>
export default ({
  mounted () {
    console.log(window.location.search)
    let url = ''
    url = decodeURIComponent(window.location.search.substring(11))
    if (url.indexOf('/account/balance&') !== -1) {
      url = url.replace('/account/balance&', '/account/balance?')
    }
    window.location.href = url
  }
})
</script>
